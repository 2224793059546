import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import MyCrewTabs from "../tabs/tabGroups/MyCrewTabs";
import axios from "axios";

export let driversResult: any;
export let crewProductionRecordsResult: any;
export let crewProductionRecordSubmittedResult: boolean;

export const MyCrewPage: React.FC = () => {
  const [drivers, setDrivers] = useState([]);
  const [crewProductionRecords, setCrewProductionRecords] = useState([]);

  const refreshDrivers = () => {
    axios
      .get(`/drivers/`)
      .then((res) => res.data)
      .then((data) => setDrivers(data));
  };

  const refreshCrewProductionRecords = () => {
    axios
      .get(`/crewProductionRecords`)
      .then((res) => res.data)
      .then((data) => setCrewProductionRecords(data));
  };

  useEffect(() => {
    refreshDrivers();
    refreshCrewProductionRecords();
  }, []);

  driversResult = drivers;
  crewProductionRecordsResult = crewProductionRecords;
  crewProductionRecordSubmittedResult = (
    crewProductionRecords?.at(0) ?? { exists: false }
  ).exists;

  return (
    <Box
      sx={{ justifyContent: "center", alignContent: "center", display: "flex" }}
    >
      <Box
        sx={{
          width: "40%",
        }}
      >
        <MyCrewTabs />
      </Box>
    </Box>
  );
};

export default MyCrewPage;
