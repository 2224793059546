import React, { useState, useEffect } from "react";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { Box, Stack, styled } from "@mui/material";
import { plantersResult } from "../pages/MyCompanyPage";

const columns: GridColDef[] = [
  {
    field: "name",
    headerName: "Name",
    align: "left",
    headerAlign: "left",
    width: 200,
    editable: false,
  },
  {
    field: "role",
    headerName: "Role",
    align: "left",
    headerAlign: "left",
    width: 150,
    editable: false,
  },
  {
    field: "crewboss_name",
    headerName: "Crewboss",
    align: "left",
    headerAlign: "left",
    width: 200,
    editable: false,
  },
  {
    field: "driver",
    headerName: "Driver",
    type: "boolean",
    align: "center",
    headerAlign: "center",
    width: 150,
    editable: false,
  },
];

export const PlantersTable: React.FC = () => {
  return (
    <Stack
      direction="column"
      sx={{
        // width: 600,
        justifyContent: "center",
        alignContent: "center",
        display: "flex",
        m: 1,
      }}
    >
      <Box
        sx={{
          height: "100%",
          // minHeight: 400,
        }}
      >
        <DataGrid
          rows={plantersResult}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 7,
              },
            },
          }}
          sx={{ background: "white" }}
          pageSizeOptions={[7]}
          // checkboxSelection
          disableRowSelectionOnClick
        />
      </Box>
    </Stack>
  );
};
