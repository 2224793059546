import React from "react";
import { Stack } from "@mui/material";
import PendingPlanterTalliesPerPlanterTable from "../tables/PendingPlanterTalliesPerPlanterTable";
import DoneOutlineIcon from "@mui/icons-material/DoneOutline";
import { PageCard } from "./cardComponents/PageCard";
import { CardTitle } from "./cardComponents/CardTitle";
import MessageWithIcon from "./cardComponents/MessageWithIcon";

// ALL TEXT ON THE SCREEN (FOR LANGUAGE PURPOSES LATER)
const TXT_TITLE_PENDING_TALLIES = "PLANTER TALLIES PENDING APPROVAL";
const TXT_INFO_NO_PENDING_TALLIES =
  "All of your submitted daily planter tallies have been approved.";

export default function PendingDailyPlanterTalliesPerPlanterCard() {
  // TODO: NOT HARD CODED
  const pendingExists = false;

  return (
    <PageCard>
      <Stack>
        <CardTitle title={TXT_TITLE_PENDING_TALLIES} />
        {pendingExists ? (
          <PendingPlanterTalliesPerPlanterTable />
        ) : (
          <MessageWithIcon
            message={TXT_INFO_NO_PENDING_TALLIES}
            icon={<DoneOutlineIcon />}
          />
        )}
      </Stack>
    </PageCard>
  );
}
