import React from "react";
import { TabPanel } from "../TabPanel";
import { Box } from "@mui/material";
import CrewProductionHistoryCard from "../../cards/CrewProductionHistoryCard";

interface Props {
  value: any;
  theme: any;
  index: number;
}

export const CrewProductionHistoryTab: React.FC<Props> = ({
  value,
  theme,
  index,
}) => {
  return (
    <TabPanel value={value} index={index} dir={theme.direction}>
      <Box
        sx={{
          // m: 1,
          display: "flex",
          justifyContent: "center",
          alignContent: "center",
        }}
      >
        <CrewProductionHistoryCard />
      </Box>
    </TabPanel>
  );
};
