import React from "react";
import { TabPanel } from "../TabPanel";
import PlantersCard from "../../cards/PlantersCard";

interface Props {
  value: any;
  theme: any;
  index: number;
}

export const PlantersTab: React.FC<Props> = ({ value, theme, index }) => {
  return (
    <TabPanel value={value} index={index} dir={theme.direction}>
      <PlantersCard />
    </TabPanel>
  );
};
