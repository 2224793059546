import React from "react";
import { TabPanel } from "../TabPanel";
import AllEmployeesCard from "../../cards/AllEmployeesCard";

interface Props {
  value: any;
  theme: any;
  index: number;
}

export const AllEmployeesTab: React.FC<Props> = ({ value, theme, index }) => {
  return (
    <TabPanel value={value} index={index} dir={theme.direction}>
      <AllEmployeesCard />
    </TabPanel>
  );
};
