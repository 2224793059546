import React, { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import DoneOutlineIcon from "@mui/icons-material/DoneOutline";
import { Box, Stack } from "@mui/material";
import { theme } from "../../App";
import CreateCrewProductionRecordFormDialog from "../forms/CreateCrewProductionRecordFormDialog";
import { crewProductionRecordSubmittedResult } from "../pages/MyCrewPage";

export default function SubmitCrewProductionRecordCard() {
  return (
    <Card
      sx={{
        width: "100%",
        outline: "solid",
        outlineColor: theme.palette.primary.dark,
        background: theme.palette.primary.main,
      }}
    >
      <CardContent>
        {crewProductionRecordSubmittedResult ? (
          <Stack
            direction="row"
            sx={{
              m: 1,
              justifyContent: "center",
              alignContent: "center",
              display: "flex",
            }}
          >
            <Box
              sx={{
                m: 1,
                justifyContent: "center",
                alignContent: "center",
                display: "flex",
              }}
            >
              <DoneOutlineIcon
                sx={{
                  height: "100%",
                  color: "white",
                }}
              />
            </Box>
            <Box
              sx={{
                m: 1,
                justifyContent: "center",
                alignContent: "center",
                display: "flex",
                color: "white",
              }}
            >
              <Typography variant="h6">
                CREW PRODUCTION RECORD SUBMITTED
              </Typography>
            </Box>
          </Stack>
        ) : (
          <Stack>
            {/* <Box
              sx={{
                m: 1,
                justifyContent: "center",
                alignContent: "center",
                display: "flex",
                color: "white",
              }}
            >
              <Typography variant="h6">NOTHING SUBMITTED TODAY</Typography>
            </Box> */}
            <Box
              sx={{
                m: 1,
                display: "flex",
                justifyContent: "center",
                alignContent: "center",
              }}
            >
              <CreateCrewProductionRecordFormDialog />
            </Box>
          </Stack>
        )}
      </CardContent>
    </Card>
  );
}
