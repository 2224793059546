import React from "react";
import Box from "@mui/material/Box";
import PlaylistAddCheckIcon from "@mui/icons-material/PlaylistAddCheck";
import TimelineIcon from "@mui/icons-material/Timeline";
import { a11yProps } from "../TabPanel";
import { BottomNavigation, BottomNavigationAction, Paper } from "@mui/material";
import { SubmitPlanterTallyTab } from "../myPlanting/SubmitPlanterTallyTab";
import { PlantingHistoryTab } from "../myPlanting/PlantingHistoryTab";
import { theme } from "../../../App";

export default function MyPlantingTabs() {
  // TAB INDEX
  const [tabIndex, setTabIndex] = React.useState(0);

  // TABS
  const tabs = [
    {
      index: 0,
      label: "DAILY PLANTER TALLY",
      icon: <PlaylistAddCheckIcon />,
      tab: <SubmitPlanterTallyTab value={tabIndex} index={0} theme={theme} />,
    },
    {
      index: 1,
      label: "PLANTING HISTORY",
      icon: <TimelineIcon />,
      tab: <PlantingHistoryTab value={tabIndex} index={1} theme={theme} />,
    },
  ];

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignContent: "center",
        width: { xs: "100%", md: "75%", lg: "50%", xl: "40%" },
      }}
    >
      <Box
        sx={{
          width: "100%",
          pb: "10%", // THIS IS BECAUSE THIS IS THE SAME AMOUNT AS THE BOTTOM NAV BAR
        }}
      >
        {tabs.map((currTab: any) => currTab.tab)}
      </Box>

      <Paper
        sx={{
          position: "fixed",
          bottom: 0,
          left: 0,
          right: 0,
          height: "10%",
        }}
        elevation={5}
      >
        <BottomNavigation
          showLabels
          value={tabIndex}
          onChange={(event, newTabIndex) => {
            setTabIndex(newTabIndex);
          }}
          sx={{
            "& .Mui-selected, .Mui-selected > svg": {
              color: "white",
              background: theme.palette.primary.main,
            },
            height: "100%",
          }}
        >
          {tabs.map((currTab: any) => (
            <BottomNavigationAction
              label={currTab.label}
              icon={currTab.icon}
              {...a11yProps(currTab.index)}
              sx={{ maxWidth: "100%" }}
            />
          ))}
        </BottomNavigation>
      </Paper>
    </Box>
  );
}
