import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import DropdownSelect from "./inputs/DropdownSelect";
import { Box, Stack } from "@mui/material";
import axios from "axios";
import { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { TextField } from "./inputs/TextField";
import { theme } from "../../App";
import { Planter, crewbossesResult } from "../pages/MyCompanyPage";

// TODO: Make this the same enum as used in the postgres database.
// OR MAYBE EVEN JUST USE AN API CALL TO GET THE ROLE TYPE VALUES
export enum Role {
  PLANTER = "PLANTER",
  CREWBOSS = "CREWBOSS",
  SUPERVISOR = "SUPERVISOR",
  KITCHEN = "KITCHEN",
}

export const roleOptions = [
  {
    label: "Planter",
    value: Role.PLANTER,
  },
  {
    label: "Crewboss",
    value: Role.CREWBOSS,
  },
  {
    label: "Supervisor",
    value: Role.SUPERVISOR,
  },
  {
    label: "Kitchen Staff",
    value: Role.KITCHEN,
  },
];

export const driverOptions = [
  {
    label: "They are not a driver",
    value: "false",
  },
  {
    label: "They are a driver",
    value: "true",
  },
];

// Defines what the fields will be in the form
interface AddEmployeeForm {
  name: string;
  role: Role;
  email: string;
  crewbossId: number;
  driver: boolean;
}

export default function AddEmployeeFormDialog() {
  // STATE VARIABLES AND FUNCTIONS
  const [formOpen, setFormOpen] = useState(false);

  // DEFINE FORM DEFAULTS
  const form = useForm<AddEmployeeForm>({
    defaultValues: {
      name: undefined,
      role: undefined,
      email: undefined,
      crewbossId: undefined,
      driver: undefined,
    },
  });

  // HANDLE ACTIONS
  const { handleSubmit, watch } = form;

  const handleCancelForm = () => {
    setFormOpen(false);
  };

  const handleClickOpenForm = () => {
    setFormOpen(true);
  };

  const roleWatch = watch("role");

  return (
    <Box>
      {/* BUTTON */}
      <Button
        sx={{
          mt: 4,
          // color: theme.palette.primary.main,
          color: "white",
          background: theme.palette.secondary.main,
          outlineColor: theme.palette.primary.light,
          outline: "solid",
          // padding: 2,
          // width: 300,
        }}
        variant="contained"
        onClick={handleClickOpenForm}
      >
        Add an Employee
      </Button>
      <Dialog open={formOpen} disableEscapeKeyDown scroll="body">
        <DialogTitle sx={{ m: 1, textAlign: "center" }}>
          ADD AN EMPLOYEE TO PLANET PLANT
        </DialogTitle>
        <DialogContent>
          <FormProvider {...form}>
            <form
              onSubmit={handleSubmit((inputValues) => {
                // DEPENDING ON ROLE, SEND DIFFERENT SUBMIT
                let endpoint = "";
                if (roleWatch === "PLANTER") {
                  endpoint = "/planters";
                } else if (roleWatch === "CREWBOSS") {
                  endpoint = "/crewbosses";
                } else if (roleWatch === "SUPERVISOR") {
                  endpoint = "/supervisors";
                } else if (roleWatch === "KITCHEN") {
                  endpoint = "/kitchen/staff";
                } else {
                  throw Error;
                }
                axios
                  .post(endpoint, JSON.parse(JSON.stringify(inputValues)))
                  .then((res) => {
                    console.log(res);
                    console.log(res.data);
                  });
                setFormOpen(false);
              })}
            >
              <Stack
                direction="column"
                alignItems="center"
                justifyContent="center"
                spacing={5}
                sx={{ m: 1 }}
              >
                {/* NAME */}
                <TextField
                  name="name"
                  label="What is the employee's name?"
                  sx={{ m: 1, width: 400 }}
                ></TextField>
                {/* EMAIL */}
                <TextField
                  name="email"
                  label="What is the employee's email?"
                  sx={{ m: 1, width: 400 }}
                ></TextField>
                {/* SELECT ROLE */}
                <DropdownSelect
                  name="role"
                  label="What is their role?"
                  dropdownOptions={roleOptions}
                />
                {/* MORE FIELDS DEPENDING ON WHICH ROLE THEY ARE */}
                {roleWatch === "PLANTER" ? (
                  <Stack spacing={5} sx={{ m: 1 }}>
                    {/* SELECT CREWBOSS */}
                    <DropdownSelect
                      name="crewbossId"
                      label="Who is their default crewboss?"
                      dropdownOptions={crewbossesResult.map(
                        (crewboss: Planter) => ({
                          label: crewboss.name,
                          value: crewboss.id.toString(),
                        })
                      )}
                    />
                    {/* DRIVER? */}
                    <DropdownSelect
                      name="driver"
                      label="Are they a driver?"
                      dropdownOptions={driverOptions}
                    />
                  </Stack>
                ) : (
                  <></>
                )}
                {/* SUBMIT FORM BUTTON */}
                <Button type="submit" variant="contained">
                  Submit
                </Button>

                {/* CANCEL BUTTON */}
                <Button
                  // onClose={handleClose}
                  onClick={handleCancelForm}
                  variant="outlined"
                  color="primary"
                >
                  Cancel
                </Button>
              </Stack>
            </form>
          </FormProvider>
        </DialogContent>
      </Dialog>
    </Box>
  );
}
