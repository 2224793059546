import * as React from "react";
import { useTheme } from "@mui/material/styles";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import GroupsIcon from "@mui/icons-material/Groups";
import ParkIcon from "@mui/icons-material/Park";
import AirportShuttleIcon from "@mui/icons-material/AirportShuttle";
import SensorOccupiedIcon from "@mui/icons-material/SensorOccupied";
import { a11yProps } from "../TabPanel";
import { BottomNavigation, BottomNavigationAction, Paper } from "@mui/material";
import { theme } from "../../../App";
import { AllEmployeesTab } from "../employeeManagement/AllEmployeesTab";
import { CrewbossesTab } from "../employeeManagement/CrewbossesTab";
import { PlantersTab } from "../employeeManagement/PlantersTab";
import { SupervisorsTab } from "../employeeManagement/SupervisorsTab";

export default function EmployeeManagementTabs() {
  // TAB INDEX
  const [tabIndex, setTabIndex] = React.useState(0);

  // TABS
  const tabs = [
    {
      index: 0,
      label: "ALL EMPLOYEES",
      icon: <GroupsIcon />,
      tab: <AllEmployeesTab value={tabIndex} index={0} theme={theme} />,
    },
    {
      index: 1,
      label: "PLANTERS",
      icon: <ParkIcon />,
      tab: <PlantersTab value={tabIndex} index={1} theme={theme} />,
    },
    {
      index: 2,
      label: "CREWBOSSES",
      icon: <AirportShuttleIcon />,
      tab: <CrewbossesTab value={tabIndex} index={2} theme={theme} />,
    },
    {
      index: 3,
      label: "SUPERVISORS",
      icon: <SensorOccupiedIcon />,
      tab: <SupervisorsTab value={tabIndex} index={3} theme={theme} />,
    },
  ];

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignContent: "center",
      }}
    >
      {tabs.map((currTab: any) => currTab.tab)}
      <Paper
        sx={{
          position: "fixed",
          bottom: 0,
          left: 0,
          right: 0,
        }}
        elevation={5}
      >
        <BottomNavigation
          showLabels
          value={tabIndex}
          onChange={(event, newTabIndex) => {
            setTabIndex(newTabIndex);
          }}
          sx={{
            "& .Mui-selected, .Mui-selected > svg": {
              color: "white",
              background: theme.palette.primary.main,
            },
          }}
        >
          {tabs.map((currTab: any) => (
            <BottomNavigationAction
              label={currTab.label}
              icon={currTab.icon}
              {...a11yProps(currTab.index)}
              sx={{ maxWidth: "100%" }}
            />
          ))}
        </BottomNavigation>
      </Paper>
    </Box>
  );
}
