import React from "react";
import Typography from "@mui/material/Typography";
import DoneOutlineIcon from "@mui/icons-material/DoneOutline";
import { Box, Stack } from "@mui/material";
import DailyPlanterTallyFormDialog from "../forms/DailyPlanterTallyFormDialog";
import { PageCard } from "./cardComponents/PageCard";
import { CardTitle } from "./cardComponents/CardTitle";
import { planterTallySubmittedResult } from "../pages/MyPlantingPage";
import MessageWithIcon from "./cardComponents/MessageWithIcon";

// ALL TEXT ON THE SCREEN (FOR LANGUAGE PURPOSES LATER)
const TXT_INFO_SUBMITTED_TALLY = "You submitted today's daily planter tally.";
const TXT_INFO_DID_NOT_SUBMIT_TALLY =
  "You haven't submitted a daily planter tally today.";
const TXT_TITLE_SUBMIT_TALLY = "PLANTER TALLY FOR TODAY";

export default function SubmitDailyPlanterTallyCard() {
  return (
    <PageCard>
      <Stack>
        <CardTitle title={TXT_TITLE_SUBMIT_TALLY} />
        {planterTallySubmittedResult ? (
          <MessageWithIcon
            message={TXT_INFO_SUBMITTED_TALLY}
            icon={<DoneOutlineIcon />}
          />
        ) : (
          <Stack>
            <MessageWithIcon
              message={TXT_INFO_DID_NOT_SUBMIT_TALLY}
              icon={null}
            />
            <DailyPlanterTallyFormDialog />
          </Stack>
        )}
      </Stack>
    </PageCard>
  );
}
