import React, { useContext, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import DoneOutlineOutlinedIcon from "@mui/icons-material/DoneOutlineOutlined";
import CachedOutlinedIcon from "@mui/icons-material/CachedOutlined";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import axios from "axios";
import { Dayjs } from "dayjs";
import { Button, IconButton, Stack } from "@mui/material";
import { theme } from "../../App";
import { TextField } from "../forms/inputs/TextField";
import { FormProvider, useForm } from "react-hook-form";
import { AuthContext } from "../../context/AuthContext";

const dropDownWidth = 60;
const dropDownColor = "";
const planterWidth = 180;
const planterColor = "";
const hoursWidth = 100;
const hoursColor = "";
const treesWidth = 100;
const treesColor = "";
const notesWidth = 210;
const notesColor = "";
const approveWidth = 115;
const approveColor = "";
const earningsWidth = 115;
const earningsColor = "";

const fieldSize = 65;

function block(
  block_id: number,
  block_name: string,
  block_total: number,
  pine: number,
  spruce: number,
  fir: number,
  tree_price: number,
  total_earnings: number
) {
  return {
    block_id,
    block_name,
    block_total,
    pine,
    spruce,
    fir,
    tree_price,
    total_earnings,
  };
}

function entry(
  daily_planting_record_id: number,
  planter_id: number,
  planter_name: string,
  plant_date: Dayjs,
  crewboss_id: number,
  crewboss_name: string,
  planting_hours: number,
  notes: string,
  approved: boolean,
  blocks: (typeof block)[],
  total_trees: number,
  total_earnings: number
) {
  return {
    daily_planting_record_id,
    planter_id,
    planter_name,
    plant_date,
    crewboss_id,
    crewboss_name,
    planting_hours,
    notes,
    approved,
    blocks,
    total_trees,
    total_earnings,
  };
}

interface Block {
  block_id: number;
  block_name: string;
  block_total: number;
  pine: number;
  spruce: number;
  fir: number;
  tree_price: number;
  total_earnings: number;
}

interface ApprovePlanterTallyForm {
  daily_planter_record_id: number;
  hours: number;
  blocks: [Block];
}

function NestedRow(props: { row: ReturnType<typeof entry> }) {
  const { row } = props;
  console.log("ROW:", row);

  const form = useForm<ApprovePlanterTallyForm>({
    defaultValues: {
      daily_planter_record_id: row.daily_planting_record_id,
      hours: 10,
    },
  });
  const { handleSubmit, watch } = form;
  // const treePriceWatch = watch("blocks.0.tree_price");
  const [open, setOpen] = React.useState(true);

  console.log("VALUES:", form.getValues());

  const setApproved = () => {
    axios
      .put(
        `/dailyPlanterTallies/approve/${row.daily_planting_record_id}`,
        JSON.parse(JSON.stringify(form.getValues()))
      )
      .then((res) => {
        console.log(res);
        console.log(res.data);
      });
  };

  return (
    <Stack>
      <FormProvider {...form}>
        <form
          style={{
            // display: "flex",
            justifyContent: "center",
            alignContent: "center",
            // width: "20%",
          }}
          onSubmit={
            // DEFINE HANDLE SUBMIT
            handleSubmit((inputValues) => {
              // DEPENDING ON ROLE, SEND DIFFERENT SUBMIT
              let endpoint = `/dailyPlanterTallies/approve/${row.daily_planting_record_id}`;
              console.log("INPUT VALUES:", inputValues);
              // axios
              //   .put(endpoint, JSON.parse(JSON.stringify(inputValues)))
              //   .then((res) => {
              //     console.log(res);
              //     console.log(res.data);
              //   });
            })
          }
        >
          <TableRow>
            <TableCell
              align="left"
              sx={{
                background: dropDownColor,
                width: dropDownWidth,
              }}
            >
              <IconButton
                aria-label="expand row"
                size="small"
                sx={{ padding: 0 }}
                onClick={() => setOpen(!open)}
              >
                {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </IconButton>
            </TableCell>
            <TableCell
              align="left"
              sx={{
                width: planterWidth,
                background: planterColor,
              }}
            >
              {row.planter_name}
            </TableCell>
            <TableCell
              align="center"
              sx={{
                width: hoursWidth,
                background: hoursColor,
              }}
            >
              {row.approved ? (
                row.planting_hours
              ) : (
                <TextField name="hours" label="" sx={{ width: 50 }} />
              )}
            </TableCell>
            <TableCell
              align="center"
              sx={{ width: treesWidth, background: treesColor }}
            >
              {row.total_trees}
            </TableCell>
            <TableCell
              align="center"
              sx={{ width: earningsWidth, background: earningsColor }}
            >
              {`$ ${row.total_earnings}`}
            </TableCell>
            <TableCell
              align="left"
              sx={{ width: notesWidth, background: notesColor }}
            >
              {row.notes}
            </TableCell>
            <TableCell
              sx={{
                width: approveWidth,
                background: approveColor,
                padding: 1,
                textAlign: "center",
                // justifyContent: "center",
                // alignContent: "center",
                // display: "flex",
              }}
            >
              {row.approved ? (
                <Box
                  sx={{
                    color: theme.palette.secondary.main,
                  }}
                >
                  <DoneOutlineOutlinedIcon />
                </Box>
              ) : (
                <Button
                  sx={{
                    // m: 1,
                    color: "white",
                    background: theme.palette.secondary.main,
                    outlineColor: theme.palette.primary.light,
                    outline: "solid",
                  }}
                  variant="contained"
                  // type="submit"
                  onClick={setApproved}
                >
                  APPROVE
                </Button>
              )}
            </TableCell>
          </TableRow>
          <Collapse in={open} unmountOnExit>
            <TableRow
              sx={{
                justifyContent: "center",
                alignContent: "center",
                display: "flex",
                background: "#DEDEDE",
              }}
            >
              <Table
                aria-label="blocks table"
                sx={{
                  width: "80%",
                }}
              >
                <TableHead>
                  <TableRow>
                    <TableCell align="left" sx={{ width: 100 }}>
                      Block Name
                    </TableCell>
                    <TableCell align="center" sx={{ width: 100 }}>
                      Tree Price
                    </TableCell>
                    <TableCell align="center" sx={{ width: 75 }}>
                      Pine
                    </TableCell>
                    <TableCell align="center" sx={{ width: 75 }}>
                      Spruce
                    </TableCell>
                    <TableCell align="center" sx={{ width: 75 }}>
                      Fir
                    </TableCell>
                    <TableCell align="center" sx={{ width: 75 }}>
                      Total
                    </TableCell>
                    <TableCell align="center" sx={{ width: 75 }}>
                      Earnings
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.blocks.map((block: any, index) => (
                    <TableRow>
                      <TableCell>
                        {row.approved ? (
                          block.block_name
                        ) : (
                          <TextField
                            name={`blocks.${block.block_id}.block_name`}
                            label=""
                            defaultValue={block.block_name}
                            sx={{ width: 90 }}
                          />
                        )}
                      </TableCell>
                      <TableCell align="center">
                        {row.approved ? (
                          `${block.tree_price}`
                        ) : (
                          <TextField
                            name={`blocks.${block.block_id}.tree_price`}
                            label=""
                            defaultValue={block.tree_price}
                            sx={{ width: fieldSize }}
                          />
                        )}
                      </TableCell>
                      <TableCell align="center">
                        {row.approved ? (
                          block.pine
                        ) : (
                          <TextField
                            name={`blocks.${block.block_id}.pine`}
                            label=""
                            defaultValue={block.pine}
                            sx={{ width: fieldSize }}
                          />
                        )}
                      </TableCell>
                      <TableCell align="center">
                        {row.approved ? (
                          block.spruce
                        ) : (
                          <TextField
                            name={`blocks.${block.block_id}.spruce`}
                            label=""
                            defaultValue={block.spruce}
                            sx={{ width: fieldSize }}
                          />
                        )}
                      </TableCell>
                      <TableCell align="center">
                        {row.approved ? (
                          block.fir
                        ) : (
                          <TextField
                            name={`blocks.${block.block_id}.fir`}
                            label=""
                            defaultValue={block.fir}
                            sx={{ width: fieldSize }}
                          />
                        )}
                      </TableCell>
                      <TableCell align="center">
                        {row.approved ? (
                          block.block_total
                        ) : (
                          <TextField
                            name={`blocks.${block.block_id}.block_total`}
                            label=""
                            value={block.block_total}
                            disabled={true}
                            sx={{ width: fieldSize }}
                          />
                        )}
                      </TableCell>
                      <TableCell align="center">
                        {row.approved ? (
                          `$ ${block.total_earnings}`
                        ) : (
                          <TextField
                            name={`blocks.${block.block_id}.total_earnings`}
                            label=""
                            value={block.total_earnings}
                            disabled={true}
                            sx={{ width: 80 }}
                          />
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableRow>
          </Collapse>
        </form>
      </FormProvider>
    </Stack>
  );
}

export default function PlanterTalliesPerCrewTable() {
  // CURRENT USER
  const user = useContext(AuthContext);

  // TODO: UPDATE
  const TEMP_USER_ID = 0;

  const [data, setData] = useState([]);

  const getDailyPlanterTalliesToday = () => {
    axios
      .get(`/dailyPlanterTallies/crewboss/${TEMP_USER_ID}/today`)
      .then((res) => res.data)
      .then((data) => setData(data));
  };

  // API EFFECT TO GET TABLE
  useEffect(() => getDailyPlanterTalliesToday(), []);

  return (
    <Stack
      direction="column"
      sx={{
        // width: 525,
        justifyContent: "center",
        alignContent: "center",
        display: "flex",
      }}
    >
      <Box>
        <TableContainer component={Paper}>
          <Table aria-label="collapsible table">
            <Stack>
              <TableHead>
                <TableRow>
                  <TableCell
                    align="center"
                    sx={{
                      background: dropDownColor,
                      maxWidth: dropDownWidth,
                    }}
                  >
                    <Button
                      variant="text"
                      sx={{
                        color: theme.palette.secondary.main,
                        padding: 0,
                        m: 0,
                        maxWidth: 30,
                      }}
                      onClick={getDailyPlanterTalliesToday}
                    >
                      <CachedOutlinedIcon sx={{ padding: 0, m: 0 }} />
                    </Button>
                  </TableCell>
                  <TableCell
                    align="left"
                    sx={{
                      width: planterWidth,
                      background: planterColor,
                    }}
                  >
                    Planter
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      width: hoursWidth,
                      background: hoursColor,
                    }}
                  >
                    Hours
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{ width: treesWidth, background: treesColor }}
                  >
                    Trees
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      width: earningsWidth,
                      background: earningsColor,
                    }}
                  >
                    Earnings
                  </TableCell>
                  <TableCell
                    align="left"
                    sx={{ width: notesWidth, background: notesColor }}
                  >
                    Notes
                  </TableCell>
                  <TableCell
                    sx={{
                      width: approveWidth,
                      background: approveColor,
                    }}
                  >
                    Approved?
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.map((row) => (
                  <NestedRow key={row} row={row} />
                ))}
              </TableBody>
            </Stack>
          </Table>
        </TableContainer>
      </Box>
    </Stack>
  );
}
