import React, { useContext } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import {
  Alert,
  Box,
  Button,
  Collapse,
  Grid,
  IconButton,
  Stack,
} from "@mui/material";
import { theme } from "../../App";
import { TextField } from "../forms/inputs/TextField";
import { FormProvider, set, useForm } from "react-hook-form";
import { PasswordField } from "../forms/inputs/PasswordField";
import { signInWithEmailAndPassword } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { auth } from "../../firebaseSetup";

// FORM FIELDS
export interface LogInFields {
  email: string;
  password: string;
}

// LOG IN CARD
export default function LogInCard() {
  // NAVIGATE
  const navigate = useNavigate();

  // SET UP FORM
  const form = useForm<LogInFields>({
    defaultValues: { email: undefined, password: undefined },
  });
  const { handleSubmit, watch } = form;

  // WATCH VALUES
  const emailWatch = watch("email");

  // FORM STATE VALUES
  const [invalidEmail, setInvalidEmail] = React.useState(false);
  const INVALID_EMAIL_MSG = "Please enter a valid email address.";

  const [missingPw, setMissingPw] = React.useState(false);
  const MISSING_PW_HELP = "Password is required.";

  const [failedLogin, setFailedLogin] = React.useState(false);
  const FAILED_LOGIN_MSG = "Invalid email address and password combination.";

  const [sentPwReset, setSentPwReset] = React.useState(false);
  const SENT_PW_RESET_MSG = `If the email address is associated with an existing account, password reset instructions have been sent to ${emailWatch}.`;

  // EMAIL REGEX CHECK
  const emailRegEx = new RegExp(
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );

  // VALIDATE EMAIL STRING
  function validateEmail(email: string) {
    if (email === "" || email === undefined) {
      setInvalidEmail(true);
      return false;
    } else if (emailRegEx.test(email)) {
      setInvalidEmail(false);
      return true;
    } else {
      setInvalidEmail(true);
      return false;
    }
  }

  // VALIDATE THAT A PASSWORD HAS BEEN ENTERED
  function validatePw(pw: string) {
    if (pw === "" || pw === undefined) {
      setMissingPw(true);
      return false;
    } else {
      setMissingPw(false);
      return true;
    }
  }

  // VALIDATE ALL INPUTS
  function validateInputs(logIn: LogInFields) {
    validateEmail(logIn.email);
    validatePw(logIn.password);
    return validateEmail(logIn.email) && validatePw(logIn.password);
  }

  // SIGN IN
  const signIn = async (logIn: LogInFields) => {
    console.log("ATTEMPTING SIGN IN...");
    await signInWithEmailAndPassword(auth, logIn.email, logIn.password).then(
      async (result) => {
        console.log("RESULT:", result);
        // Pick the result and store the token
        const token = await auth?.currentUser?.getIdToken(true);
        console.log("SIGN IN USER TOKEN:", token);

        //Check if have token is in the current user
        if (token) {
          console.log("SIGNED IN!");
          // Put the token at localStorage (We'll use this to make requests)
          localStorage.setItem("@token", token);
          // Navigate user to the My Planting page
          navigate("/MyPlanting");
        }
      },
      function (error) {
        console.error(error);
        setFailedLogin(true);
      }
    );
  };

  // SEND A FORGOT PASSWORD EMAIL
  function sendForgotPasswordEmail() {
    setSentPwReset(true);
  }

  // THE COMPONENT
  return (
    <Grid
      container
      sx={{
        height: "90vh",
        display: "flex",
        alignContent: "center",
        justifyContent: "center",
      }}
    >
      <Grid
        item
        xs={11}
        sx={{
          justifyContent: "center",
          alignContent: "center",
          display: "flex",
        }}
      >
        <Card
          sx={{
            borderRadius: 2,
            padding: 1,
            width: 500,
            border: "solid",
            borderColor: theme.palette.primary.main,
          }}
        >
          <CardContent>
            <Stack
              direction="column"
              spacing={3}
              sx={{
                alignContent: "center",
                justifyContent: "center",
                display: "flex",
              }}
            >
              <Typography
                variant="h5"
                textAlign={"center"}
                sx={{
                  fontWeight: "100",
                  letterSpacing: 0,
                  padding: 3,
                  borderRadius: 2,
                  color: "white",
                  background: theme.palette.primary.main,
                  border: "solid",
                  borderColor: theme.palette.secondary.main,
                }}
              >
                Welcome to{" "}
                <Box
                  fontWeight="800"
                  //   display="inline"
                  letterSpacing={5}
                >
                  {"  "}
                  PLANT PLANET
                </Box>
              </Typography>
              <FormProvider {...form}>
                <form
                  onSubmit={handleSubmit((inputValues) => {
                    if (validateInputs(inputValues)) signIn(inputValues);
                  })}
                >
                  <Collapse in={failedLogin}>
                    <Alert
                      severity="error"
                      action={
                        <IconButton
                          aria-label="close"
                          color="inherit"
                          size="small"
                          onClick={() => {
                            setFailedLogin(false);
                          }}
                        >
                          <CloseIcon fontSize="inherit" />
                        </IconButton>
                      }
                      sx={{ mb: 2 }}
                    >
                      {FAILED_LOGIN_MSG}
                    </Alert>
                  </Collapse>
                  <Collapse in={sentPwReset}>
                    <Alert
                      severity="success"
                      action={
                        <IconButton
                          aria-label="close"
                          color="inherit"
                          size="small"
                          onClick={() => {
                            setSentPwReset(false);
                          }}
                        >
                          <CloseIcon fontSize="inherit" />
                        </IconButton>
                      }
                      sx={{ mb: 2 }}
                    >
                      {SENT_PW_RESET_MSG}
                    </Alert>
                  </Collapse>
                  <Stack
                    direction="column"
                    alignItems="center"
                    justifyContent="center"
                    spacing={3}
                  >
                    <TextField
                      error={invalidEmail}
                      helperText={invalidEmail ? INVALID_EMAIL_MSG : ""}
                      name="email"
                      label="Email"
                      onFocus={() => {
                        setSentPwReset(false);
                        setFailedLogin(false);
                        setInvalidEmail(false);
                      }}
                      sx={{
                        width: "100%",
                      }}
                    ></TextField>
                    <PasswordField
                      error={missingPw}
                      helperText={missingPw ? MISSING_PW_HELP : ""}
                      onFocus={() => {
                        setMissingPw(false);
                        setSentPwReset(false);
                        setFailedLogin(false);
                      }}
                    />
                    <Stack sx={{ width: "100%" }}>
                      <Button
                        type="submit"
                        variant="contained"
                        sx={{
                          width: "100%",
                          height: 50,
                          color: "white",
                          background: theme.palette.secondary.main,
                          border: "solid",
                          borderColor: theme.palette.primary.main,
                        }}
                      >
                        Log In
                      </Button>
                      <Button
                        variant="text"
                        sx={{
                          mt: 2,
                          paddingBottom: 0,
                          width: "100%",
                          color: "gray",
                        }}
                        onClick={() => {
                          if (validateEmail(emailWatch))
                            sendForgotPasswordEmail();
                        }}
                      >
                        Forgot Password?
                      </Button>
                    </Stack>
                  </Stack>
                </form>
              </FormProvider>
            </Stack>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}
