import React, { useContext, useEffect, useState } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { Box, Stack } from "@mui/material";
import axios from "axios";
import { theme } from "../../App";
import PlanterTalliesPerCrewTable from "../tables/PlanterTalliesPerCrewTable";
import { AuthContext } from "../../context/AuthContext";

export default function PendingDailyPlanterTalliesPerCrewCard() {
  // CURRENT USER
  const user = useContext(AuthContext);

  // TODO: UPDATE
  const TEMP_USER_ID = 0;

  const [data, setData] = useState([]);
  // API EFFECT TO GET TABLE
  useEffect(() => {
    axios
      .get(`/dailyPlanterTallies/pending/crewboss/${TEMP_USER_ID}`)
      .then((res) => res.data)
      .then((data) => setData(data));
  }, []);

  return (
    <Card
      sx={{
        // width: 700,
        // m: 5,
        paddingRight: 2,
        paddingLeft: 2,
        outline: "solid",
        outlineColor: theme.palette.primary.dark,
        background: theme.palette.primary.main,
      }}
    >
      <CardContent>
        {/* {pendingExists ? ( */}
        <Stack>
          <Box
            sx={{
              // m: 1,
              paddingBottom: 3,
              justifyContent: "center",
              alignContent: "center",
              display: "flex",
              color: "white",
            }}
          >
            <Typography variant="h6">TODAY'S DAILY PLANTER TALLIES</Typography>
          </Box>
          <PlanterTalliesPerCrewTable />
        </Stack>
        {/* )  */}
        {/* : (
          <Stack
            direction="row"
            sx={{
              m: 1,
              justifyContent: "center",
              alignContent: "center",
              display: "flex",
            }}
          >
            <Box
              sx={{
                m: 1,
                justifyContent: "center",
                alignContent: "center",
                display: "flex",
              }}
            >
              <DoneOutlineIcon
                sx={{
                  height: "100%",
                  color: "white",
                }}
              />
            </Box>
            <Box
              sx={{
                m: 1,
                justifyContent: "center",
                alignContent: "center",
                display: "flex",
                color: "white",
              }}
            >
              <Typography variant="h6">
                NO DAILY PLANTER TALLIES PENDING APPROVAL
              </Typography>
            </Box>
          </Stack> */}
        {/* )} */}
      </CardContent>
    </Card>
  );
}
