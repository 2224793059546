import React from "react";
import { TabPanel } from "../TabPanel";
import CrewbossesCard from "../../cards/CrewbossesCard";

interface Props {
  value: any;
  theme: any;
  index: number;
}

export const CrewbossesTab: React.FC<Props> = ({ value, theme, index }) => {
  return (
    <TabPanel value={value} index={index} dir={theme.direction}>
      <CrewbossesCard />
    </TabPanel>
  );
};
