import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import MyCampTabs from "../tabs/tabGroups/MyCampTabs";
import axios from "axios";

export let crewProductionRecordsResult: any;
export let crewProductionRecordSubmittedResult: boolean;

export const MyCampPage: React.FC = () => {
  const [crewProductionRecords, setCrewProductionRecords] = useState([]);

  const refreshCrewProductionRecords = () => {
    axios
      .get(`/crewProductionRecords`)
      .then((res) => res.data)
      .then((data) => setCrewProductionRecords(data));
  };

  useEffect(() => {
    refreshCrewProductionRecords();
  }, []);

  crewProductionRecordsResult = crewProductionRecords;
  crewProductionRecordSubmittedResult = (
    crewProductionRecords?.at(0) ?? { exists: false }
  ).exists;

  return (
    <Box
      sx={{ justifyContent: "center", alignContent: "center", display: "flex" }}
    >
      <Box
        sx={{
          width: "50%",
        }}
      >
        <MyCampTabs />
      </Box>
    </Box>
  );
};

export default MyCampPage;
