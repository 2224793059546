import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import EmployeeManagementTabs from "../tabs/tabGroups/EmployeeManagementTabs";
import axios from "axios";

// TODO: PUT THESE IN A FILE WITH ALL THE OTHER INTERFACES (?)
export interface Planter {
  id: number;
  name: string;
  role: string;
  crewboss_id: number;
  crewboss_name: string;
  driver: boolean;
}

// QUESTION:  THERE HAS GOT TO BE A BETTER WAY TO EXPORT THIS TO
//            BE ABLE TO REFRESH AND ACCESS FROM OUTSIDE OF THIS
//            CLASS (? FILE)
export let employeesResult: any;
export let plantersResult: any;
export let crewbossesResult: any;
export let supervisorsResult: any;

export const MyCompanyPage: React.FC = () => {
  // STATE VARIABLES (DON'T REALLY UNDERSTAND WHAT STATE IS YET)
  const [employees, setEmployees] = useState([]);
  const [planters, setPlanters] = useState([]);
  const [crewbosses, setCrewbosses] = useState([]);
  const [supervisors, setSupervisors] = useState([]);

  // EMPLOYEES
  const refreshEmployees = () => {
    axios
      .get("/employees")
      .then((res) => res.data)
      .then((data) => setEmployees(data));
  };

  // PLANTERS
  const refreshPlanters = () => {
    axios
      .get("/planters")
      .then((res) => res.data)
      .then((data) => setPlanters(data));
  };

  // CREWBOSSES
  const refreshCrewbosses = () => {
    axios
      .get("/crewbosses")
      .then((res) => res.data)
      .then((data) => setCrewbosses(data));
  };

  const refreshSupervisors = () => {
    axios
      .get("/supervisors")
      .then((res) => res.data)
      .then((data) => setSupervisors(data));
  };

  // SET THE GLOBAL VARIABLES TO BE ACCESSED FROM ELSEWHERE
  employeesResult = employees;
  plantersResult = planters;
  crewbossesResult = crewbosses;
  supervisorsResult = supervisors;

  // ON LOAD (I'M PRETTY SURE)
  useEffect(() => {
    refreshEmployees();
    refreshPlanters();
    refreshCrewbosses();
    refreshSupervisors();
  }, []);

  // RETURN THE TABS!
  return (
    <Box
      sx={{ justifyContent: "center", alignContent: "center", display: "flex" }}
    >
      <Box
        sx={{
          width: "50%",
        }}
      >
        <EmployeeManagementTabs />
      </Box>
    </Box>
  );
};

export default MyCompanyPage;
