import React from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import dayjs from "dayjs";
import { crewProductionRecordsResult } from "../pages/MyCrewPage";
import { Stack } from "@mui/material";

const crewbossWidth = 150;
const crewbossColor = "";
const plantDateWidth = 100;
const plantDateColor = "";
const drivingHoursWidth = 120;
const drivingHoursColor = "";
const driversWidth = 200;
const driversColor = "";
const notesWidth = 210;
const notesColor = "";
const signedOutWidth = 110;
const signedOutColor = "";
const transferredWidth = 110;
const transferredColor = "";
const totalPlantedWidth = 110;
const totalPlantedColor = "";
const treesLeftWidth = 120;
const treesLeftColor = "";

interface CrewProductionRecord {
  id: number;
  crewboss_id: number;
  plant_date: any;
  driving_hours: number;
  drivers: string;
  notes: string;
  planted_today: number;
  signed_out: number;
  transferred: number;
  trees_in_field: number;
}

export default function CrewProductionHistoryTable() {
  return (
    <Stack
      direction="column"
      sx={{
        width: 1080,
        justifyContent: "center",
        alignContent: "center",
        display: "flex",
      }}
    >
      <Box>
        <TableContainer component={Paper}>
          <Table aria-label="collapsible table">
            <Stack>
              <TableHead>
                <TableRow>
                  {/* <TableCell
                    align="center"
                    sx={{
                      width: crewbossWidth,
                      background: crewbossColor,
                    }}
                  >
                    Crewboss
                  </TableCell> */}
                  <TableCell
                    align="center"
                    sx={{
                      width: plantDateWidth,
                      background: plantDateColor,
                    }}
                  >
                    Plant Date
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      width: drivingHoursWidth,
                      background: drivingHoursColor,
                    }}
                  >
                    Driving Hours
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      width: driversWidth,
                      background: driversColor,
                    }}
                  >
                    Drivers
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      width: signedOutWidth,
                      background: signedOutColor,
                    }}
                  >
                    Signed Out
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      width: transferredWidth,
                      background: transferredColor,
                    }}
                  >
                    Transferred
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      width: totalPlantedWidth,
                      background: totalPlantedColor,
                    }}
                  >
                    Planted
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      width: treesLeftWidth,
                      background: treesLeftColor,
                    }}
                  >
                    Left in Field
                  </TableCell>
                  <TableCell
                    align="left"
                    sx={{ width: notesWidth, background: notesColor }}
                  >
                    Notes
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {crewProductionRecordsResult.map(
                  (record: CrewProductionRecord) => (
                    <TableRow>
                      {/* <TableCell
                        align="center"
                        sx={{
                          width: crewbossWidth,
                          background: crewbossColor,
                        }}
                      >
                        {record.crewboss_id}
                      </TableCell> */}
                      <TableCell
                        align="center"
                        sx={{
                          width: plantDateWidth,
                          background: plantDateColor,
                        }}
                      >
                        {dayjs(record.plant_date).format("MMMM D")}
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{
                          width: drivingHoursWidth,
                          background: drivingHoursColor,
                        }}
                      >
                        {record.driving_hours}
                      </TableCell>
                      <TableCell
                        sx={{
                          width: driversWidth,
                          background: driversColor,
                        }}
                      >
                        {record.drivers}
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{
                          width: signedOutWidth,
                          background: signedOutColor,
                        }}
                      >
                        {record.signed_out}
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{
                          width: transferredWidth,
                          background: transferredColor,
                        }}
                      >
                        {record.transferred}
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{
                          width: totalPlantedWidth,
                          background: totalPlantedColor,
                        }}
                      >
                        {record.planted_today}
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{
                          width: treesLeftWidth,
                          background: treesLeftColor,
                        }}
                      >
                        {record.trees_in_field}
                      </TableCell>
                      <TableCell
                        sx={{
                          width: notesWidth,
                          background: notesColor,
                        }}
                      >
                        {record.notes}
                      </TableCell>
                    </TableRow>
                  )
                )}
              </TableBody>
            </Stack>
          </Table>
        </TableContainer>
      </Box>
    </Stack>
  );
}
