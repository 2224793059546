import React from "react";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { Box, Stack } from "@mui/material";
import { supervisorsResult } from "../pages/MyCompanyPage";

const columns: GridColDef[] = [
  {
    field: "name",
    headerName: "Name",
    width: 200,
    editable: false,
  },
];

export const SupervisorsTable: React.FC = () => {
  return (
    <Stack
      direction="column"
      sx={{
        justifyContent: "center",
        alignContent: "center",
        display: "flex",
        m: 1,
      }}
    >
      <Box
        sx={
          {
            // height: 1000,
          }
        }
      >
        <DataGrid
          rows={supervisorsResult}
          columns={columns}
          sx={{ background: "white" }}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 7,
              },
            },
          }}
          pageSizeOptions={[7]}
          // checkboxSelection
          disableRowSelectionOnClick
        />
      </Box>
    </Stack>
  );
};
