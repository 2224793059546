import React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { Box, Stack } from "@mui/material";
import { theme } from "../../App";
import { EmployeesTable } from "../tables/EmployeesTable";
import AddEmployeeFormDialog from "../forms/AddEmployeeFormDialog";

export default function AllEmployeesCard() {
  return (
    <Card
      sx={{
        // width: 700,
        // m: 5,
        mb: 5,
        paddingRight: 2,
        paddingLeft: 2,
        outline: "solid",
        outlineColor: theme.palette.primary.dark,
        background: theme.palette.primary.main,
      }}
    >
      <CardContent>
        <Stack>
          <Box
            sx={{
              // m: 1,
              paddingBottom: 3,
              justifyContent: "center",
              alignContent: "center",
              display: "flex",
              color: "white",
            }}
          >
            <Typography variant="h6">ALL EMPLOYEES</Typography>
          </Box>
          <EmployeesTable />
          <Box
            sx={{
              mb: 1,
              display: "flex",
              justifyContent: "center",
              alignContent: "center",
            }}
          >
            <AddEmployeeFormDialog />
          </Box>
        </Stack>
      </CardContent>
    </Card>
  );
}
