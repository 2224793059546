import React, { useContext, useState } from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import ForestIcon from "@mui/icons-material/Forest";
import { useNavigate } from "react-router-dom";
import { Button, Menu, MenuItem, Stack } from "@mui/material";
import { signOut } from "firebase/auth";
import { auth } from "../firebaseSetup";
import { AuthContext } from "../context/AuthContext";

// MAIN MENU OPTIONS WITH ENDPOINTS
const options = [
  { name: "My Planting", endpoint: "/myplanting" },
  { name: "My Crew", endpoint: "/mycrew" },
  { name: "My Camp", endpoint: "/mycamp" },
  { name: "Employee Management", endpoint: "/employees" },
  { name: "Log Out", endpoint: "/logout" },
];

function MainMenu() {
  // CURRENT USER
  const user = useContext(AuthContext);

  console.log("USER:", user);

  // NAVIGATE
  const navigate = useNavigate();

  // ANCHOR ELEMENT
  const [anchorEl, setAnchorEl] = useState(null);

  // OPEN MENU
  const handleOpenMenu = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  // CLOSE MENU
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  // SELECT MENU ITEM
  const handleSelectMenuItem = (event: any) => {
    const endpoint = event.target.id;
    if (endpoint === "/logout") {
      signOut(auth);
      navigate("/login");
    } else {
      navigate(endpoint);
    }
    setAnchorEl(null);
  };

  return (
    <React.Fragment>
      {user ? (
        <AppBar
          position="sticky"
          sx={{
            width: "100%",
            height: "12vh",
            justifyContent: "center",
            display: "flex",
            alignContent: "center",
          }}
        >
          <Container
            sx={{
              justifyContent: "center",
              display: "flex",
              alignContent: "center",
              width: "100%",
            }}
          >
            <Toolbar sx={{ width: "100%" }}>
              <Button
                key={"main_menu"}
                onClick={handleOpenMenu}
                sx={{
                  color: "white",
                  display: "flex",
                  width: "100%",
                  justifyContent: "center",
                  alignContent: "center",
                }}
              >
                <Stack direction="row">
                  <Typography
                    variant="h5"
                    // noWrap
                    component="a"
                    sx={{
                      m: 2,
                      fontFamily: "inherit",
                      fontWeight: 600,
                      letterSpacing: ".4rem",
                      color: "white",
                      textDecoration: "none",
                      textIndent: ".4rem",
                    }}
                  >
                    PLANT
                  </Typography>
                  <ForestIcon
                    fontSize="large"
                    sx={{
                      display: "flex",
                      mt: 2,
                      color: "white",
                    }}
                  />
                  <Typography
                    variant="h5"
                    // noWrap
                    component="a"
                    sx={{
                      m: 2,
                      fontFamily: "inherit",
                      fontWeight: 600,
                      letterSpacing: ".4rem",
                      color: "white",
                      textDecoration: "none",
                      textIndent: ".4rem",
                    }}
                  >
                    PLANET
                  </Typography>
                </Stack>
              </Button>

              {/* </Tooltip> */}
              <Menu
                id="menu-appbar"
                variant="selectedMenu"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
                open={Boolean(anchorEl)}
                onClose={handleCloseMenu}
              >
                {options.map((option) => (
                  <MenuItem
                    id={option.endpoint}
                    key={option.name}
                    onClick={handleSelectMenuItem}
                    sx={{
                      justifyContent: "center",
                    }}
                  >
                    <Typography id={option.endpoint}>{option.name}</Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Toolbar>
          </Container>
        </AppBar>
      ) : null}
    </React.Fragment>
  );
}
export default MainMenu;
