import React from "react";
import { Box, Typography } from "@mui/material";
import { theme } from "../../../App";

interface CardTitleProps {
  title: string;
  dir?: string;
}

export function CardTitle(props: CardTitleProps) {
  const { title, ...other } = props;
  return (
    <Box
      sx={{
        justifyContent: "center",
        alignContent: "center",
        display: "flex",
        padding: 1,
        marginBottom: 2,
        borderRadius: 2,
        border: 2,
        borderColor: theme.palette.primary.dark,
        background: theme.palette.primary.main,
      }}
    >
      <Typography variant="h6" sx={{ textAlign: "center" }}>
        {title}
      </Typography>
    </Box>
  );
}
