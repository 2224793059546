import React from "react";
import Box from "@mui/material/Box";
import PlaylistAddCheckIcon from "@mui/icons-material/PlaylistAddCheck";
import TimelineIcon from "@mui/icons-material/Timeline";
import { a11yProps } from "../TabPanel";
import { BottomNavigation, BottomNavigationAction, Paper } from "@mui/material";
import { theme } from "../../../App";
import { SubmitCrewProductionRecordTab } from "../myCrew/SubmitCrewProductionRecordTab";
import { CrewProductionHistoryTab } from "../myCrew/CrewProductionHistoryTab";

export default function MyCrewTabs() {
  // TAB INDEX
  const [tabIndex, setTabIndex] = React.useState(0);

  // TABS
  const tabs = [
    {
      index: 0,
      label: "SUBMIT CREW PRODUCTION RECORD",
      icon: <PlaylistAddCheckIcon />,
      tab: (
        <SubmitCrewProductionRecordTab
          value={tabIndex}
          index={0}
          theme={theme}
        />
      ),
    },
    {
      index: 1,
      label: "CREW PRODUCTION HISTORY",
      icon: <TimelineIcon />,
      tab: (
        <CrewProductionHistoryTab value={tabIndex} index={1} theme={theme} />
      ),
    },
  ];

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignContent: "center",
      }}
    >
      {tabs.map((currTab: any) => currTab.tab)}
      <Paper
        sx={{
          position: "fixed",
          bottom: 0,
          left: 0,
          right: 0,
        }}
        elevation={5}
      >
        <BottomNavigation
          showLabels
          value={tabIndex}
          onChange={(event, newTabIndex) => {
            setTabIndex(newTabIndex);
          }}
          sx={{
            "& .Mui-selected, .Mui-selected > svg": {
              color: "white",
              background: theme.palette.primary.main,
            },
          }}
        >
          {tabs.map((currTab: any) => (
            <BottomNavigationAction
              label={currTab.label}
              icon={currTab.icon}
              {...a11yProps(currTab.index)}
              sx={{ maxWidth: "100%" }}
            />
          ))}
        </BottomNavigation>
      </Paper>
    </Box>
  );
}
