import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
    apiKey: "AIzaSyBIgkhIzspCVirwyBoCECrMnSy2zQ1i4j4",
    authDomain: "plant-planet-123123.firebaseapp.com",
  };

const clientApp = initializeApp(firebaseConfig, 'client');

export const auth = getAuth(clientApp);
