import React from "react";
import { TabPanel } from "../TabPanel";
import { Box, Card, CardContent } from "@mui/material";
import CreateCrewProductionRecordFormDialog from "../../forms/CreateCrewProductionRecordFormDialog";
import SubmitCrewProductionRecordCard from "../../cards/SubmitCrewProductionRecordCard";

interface Props {
  value: any;
  theme: any;
  index: number;
}

export const SubmitCrewProductionRecordTab: React.FC<Props> = ({
  value,
  theme,
  index,
}) => {
  return (
    <TabPanel value={value} index={index} dir={theme.direction}>
      <SubmitCrewProductionRecordCard />
    </TabPanel>
  );
};
