import React from "react";
import { TabPanel } from "../TabPanel";
import { SupervisorsTable } from "../../tables/SupervisorsTable";
import SupervisorsCard from "../../cards/SupervisorsCard";

interface Props {
  value: any;
  theme: any;
  index: number;
}

export const SupervisorsTab: React.FC<Props> = ({ value, theme, index }) => {
  return (
    <TabPanel value={value} index={index} dir={theme.direction}>
      <SupervisorsCard />
    </TabPanel>
  );
};
