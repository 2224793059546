import React from "react";
import { Card, CardContent } from "@mui/material";
import { theme } from "../../../App";

interface PageCardProps {
  children?: React.ReactNode;
  dir?: string;
}

export function PageCard(props: PageCardProps) {
  const { children, ...other } = props;
  return (
    <Card
      sx={{
        color: "white",
        borderRadius: 2,
        border: "solid",
        borderColor: theme.palette.primary.main,
        background: theme.palette.secondary.main,
        ...other,
      }}
    >
      <CardContent>{children}</CardContent>
    </Card>
  );
}
