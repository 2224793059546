import React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { Box, Stack } from "@mui/material";
import { theme } from "../../App";
import CrewProductionHistoryTable from "../tables/CrewProductionHistoryTable";

export default function CrewProductionHistoryCard() {
  return (
    <Card
      sx={{
        paddingRight: 2,
        paddingLeft: 2,
        outline: "solid",
        outlineColor: theme.palette.primary.dark,
        background: theme.palette.primary.main,
      }}
    >
      <CardContent>
        <Stack>
          <Box
            sx={{
              // m: 1,
              paddingBottom: 3,
              justifyContent: "center",
              alignContent: "center",
              display: "flex",
              color: "white",
            }}
          >
            <Typography variant="h6">
              YOUR PAST CREW PRODUCTION RECORDS
            </Typography>
          </Box>
          <CrewProductionHistoryTable />
        </Stack>
      </CardContent>
    </Card>
  );
}
