import React, { useContext, useEffect } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Box, Card, CardContent, Stack, Typography } from "@mui/material";
import axios from "axios";
import { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { theme } from "../../App";
import PendingDailyPlanterTalliesPerCrewCard from "../cards/PendingDailyPlanterTalliesPerCrewCard";
import { TextField } from "./inputs/TextField";
import { DatePicker } from "./inputs/DatePicker";
import dayjs from "dayjs";
import ChipSelect from "./inputs/ChipSelect";
import { driversResult } from "../pages/MyCrewPage";
import { AuthContext } from "../../context/AuthContext";

// Defines what the fields will be in the form
interface CreateCrewProductionRecordForm {
  crewbossId: number;
  yesterday: number;
  transferred: number;
  signed_out: number;
  planted_today: number;
  driving_hours: number;
  drivers: [string];
}

export default function CreateCrewProductionRecordFormDialog() {
  // CURRENT USER
  const user = useContext(AuthContext);

  // TODO: UPDATE
  const TEMP_USER_ID = 0;
  const TEMP_USER_NAME = "Anneke van der Laan";

  // STATE VARIABLES AND FUNCTIONS
  const [formOpen, setFormOpen] = useState(false);
  const [total, setTotal] = useState([]);

  // DEFINE FORM DEFAULTS
  const form = useForm<CreateCrewProductionRecordForm>({
    defaultValues: {
      crewbossId: undefined,
      yesterday: 0,
      transferred: 0,
      signed_out: 0,
      driving_hours: undefined,
      drivers: undefined,
    },
  });

  // HANDLE ACTIONS
  const { handleSubmit, watch } = form;
  const handleCancelForm = () => {
    setFormOpen(false);
  };
  const handleClickOpenForm = () => {
    setFormOpen(true);
  };

  useEffect(() => {
    axios
      .get(`/crew/today/total/${TEMP_USER_ID}`)
      .then((res) => res.data)
      .then((data) => setTotal(data));
  }, []);

  const yesterdayWatch: number = watch("yesterday");
  const transferredWatch: number = watch("transferred");
  const signedOutWatch: number = watch("signed_out");

  const treeTotal = total.at(0) as unknown as {
    sum: number;
  };

  const treesLeft: number =
    Number(yesterdayWatch) +
    Number(transferredWatch) +
    Number(signedOutWatch) -
    Number(treeTotal?.sum ?? 0);

  return (
    <Box>
      {/* BUTTON */}
      <Button
        sx={{
          // color: theme.palette.primary.main,
          color: "white",
          background: theme.palette.secondary.main,
          outlineColor: theme.palette.primary.light,
          outline: "solid",
          // padding: 2,
          // width: 300,
        }}
        variant="contained"
        onClick={handleClickOpenForm}
      >
        Add a Crew Production Record
      </Button>

      {/* OPEN THE DIALOG */}
      <Dialog
        open={formOpen}
        // onClose={handleCancelForm}
        maxWidth="lg"
        disableEscapeKeyDown
        scroll="body"
      >
        {/* TITLE */}
        <DialogTitle sx={{ m: 1, textAlign: "center" }}>
          ADD A CREW PRODUCTION RECORD TO PLANET PLANT
        </DialogTitle>

        {/* CONTENTS OF DIALOG (THE FORM) */}
        <DialogContent>
          <FormProvider {...form}>
            <form
              onSubmit={
                // DEFINE HANDLE SUBMIT
                handleSubmit((inputValues) => {
                  console.log("SUBMIT RESULT", { inputValues });
                  let endpoint = "/crewProductionRecord";
                  axios
                    .post(endpoint, JSON.parse(JSON.stringify(inputValues)))
                    .then((res) => {
                      console.log(res);
                      console.log(res.data);
                    });
                  setFormOpen(false);
                })
              }
            >
              <Stack
                direction="column"
                alignItems="center"
                justifyContent="center"
                spacing={5}
                sx={{ m: 1 }}
              >
                <Stack direction="row" spacing={5}>
                  {/* SELECT CREWBOSS */}
                  <TextField
                    disabled={true}
                    name="crewboss_id"
                    value={TEMP_USER_NAME}
                    label="Crewboss"
                  />

                  <DatePicker
                    name="plantDate"
                    label="Plant Date"
                    value={dayjs()}
                    disabled={true}

                    // sx={{ m: 1,
                    // width: 400 }}
                  />
                </Stack>
                <TextField name="notes" label="Notes" sx={{ width: 500 }} />
                {/* APPROVE TABLE */}
                <PendingDailyPlanterTalliesPerCrewCard />
                <Card
                  sx={{
                    // width: 700,
                    // m: 5,
                    // mb: 5,
                    // paddingRight: 2,
                    // paddingLeft: 2,
                    // width: "100%",
                    outline: "solid",
                    outlineWidth: 5,
                    outlineColor: theme.palette.primary.main,
                    // background: theme.palette.primary.main,
                  }}
                >
                  <CardContent
                    sx={{
                      justifyContent: "center",
                      alignContent: "center",
                      display: "flex",
                    }}
                  >
                    <Stack direction="column" spacing={4} sx={{ width: 400 }}>
                      <Box
                        sx={{
                          // m: 1,
                          // paddingBottom: 3,
                          justifyContent: "center",
                          alignContent: "center",
                          display: "flex",
                          background: theme.palette.primary.main,
                          color: "white",
                          padding: 1,
                        }}
                      >
                        <Typography variant="h6">SEEDLOT SUMMARIES</Typography>
                      </Box>
                      <Box
                        sx={{
                          justifyContent: "center",
                          alignContent: "center",
                          display: "flex",
                        }}
                      >
                        <Stack>
                          <Typography variant="body1" textAlign={"center"}>
                            WORK IN PROGRESS:
                          </Typography>
                          <Typography variant="body2" textAlign={"left"}>
                            - totals per seedlot per block
                          </Typography>
                          <Typography variant="body2" textAlign={"left"}>
                            - ratios per block
                          </Typography>
                          <Typography variant="body2" textAlign={"left"}>
                            - totals per seedlot for the day
                          </Typography>
                          <Typography variant="body2" textAlign={"left"}>
                            - ratios for the day
                          </Typography>
                        </Stack>
                      </Box>
                    </Stack>
                  </CardContent>
                </Card>
                <Card
                  sx={{
                    // width: 700,
                    // m: 5,
                    // mb: 5,
                    // paddingRight: 2,
                    // paddingLeft: 2,
                    // width: "100%",
                    outline: "solid",
                    outlineWidth: 5,
                    outlineColor: theme.palette.primary.main,
                    // background: theme.palette.primary.main,
                  }}
                >
                  <CardContent
                    sx={{
                      justifyContent: "center",
                      alignContent: "center",
                      display: "flex",
                    }}
                  >
                    <Stack direction="column" spacing={4} sx={{ width: 400 }}>
                      <Box
                        sx={{
                          // m: 1,
                          // paddingBottom: 3,
                          justifyContent: "center",
                          alignContent: "center",
                          display: "flex",
                          background: theme.palette.primary.main,
                          color: "white",
                          padding: 1,
                        }}
                      >
                        <Typography variant="h6">TREE TRACKING</Typography>
                      </Box>
                      <Stack
                        direction="column"
                        spacing={2}
                        sx={{
                          justifyContent: "center",
                          alignContent: "center",
                          display: "flex",
                        }}
                      >
                        <TextField
                          name="yesterday"
                          label="Trees Left In Field Yesterday"
                        />
                        <TextField
                          name="signed_out"
                          label="New Trees Signed Out Today"
                        />
                        <TextField
                          name="transferred"
                          label="Trees Transferred In/Out"
                        />
                      </Stack>
                      <Stack
                        direction="row"
                        spacing={2}
                        sx={{
                          justifyContent: "center",
                          alignContent: "center",
                          display: "flex",
                        }}
                      >
                        <TextField
                          name="planted_today"
                          label="Trees Planted Today"
                          value={treeTotal?.sum ?? 0}
                        />
                        <TextField
                          name="trees_left_in_field_today"
                          label="Trees Left in Field Today"
                          value={treesLeft}
                        />
                      </Stack>
                    </Stack>
                  </CardContent>
                </Card>
                <Card
                  sx={{
                    // width: 700,
                    // m: 5,
                    // mb: 5,
                    // paddingRight: 2,
                    // paddingLeft: 2,
                    // width: "100%",
                    outline: "solid",
                    outlineWidth: 5,
                    outlineColor: theme.palette.primary.main,
                    // background: theme.palette.primary.main,
                  }}
                >
                  <CardContent
                    sx={{
                      justifyContent: "center",
                      alignContent: "center",
                      display: "flex",
                    }}
                  >
                    <Stack direction="column" spacing={4} sx={{ width: 400 }}>
                      <Box
                        sx={{
                          // m: 1,
                          // paddingBottom: 3,
                          justifyContent: "center",
                          alignContent: "center",
                          display: "flex",
                          background: theme.palette.primary.main,
                          color: "white",
                          padding: 1,
                        }}
                      >
                        <Typography variant="h6">DRIVERS</Typography>
                      </Box>
                      <Stack
                        direction="column"
                        spacing={2}
                        sx={{
                          justifyContent: "center",
                          alignContent: "center",
                          display: "flex",
                        }}
                      >
                        <TextField name="driving_hours" label="Driving Hours" />
                        <ChipSelect chipOptions={driversResult} />
                      </Stack>
                    </Stack>
                  </CardContent>
                </Card>
                {/* SUBMIT FORM BUTTON */}
                <Button type="submit" variant="contained">
                  Submit
                </Button>

                {/* CANCEL BUTTON */}
                <Button
                  onClick={handleCancelForm}
                  variant="outlined"
                  color="primary"
                >
                  Cancel
                </Button>
              </Stack>
            </form>
          </FormProvider>
        </DialogContent>
      </Dialog>
    </Box>
  );
}
