import React from "react";
import { Stack, SvgIcon, Typography } from "@mui/material";
import { Symbol } from "typescript";

interface MessageWithIconProps {
  message: string;
  icon: any;
}

export default function MessageWithIcon(props: MessageWithIconProps) {
  const { message, icon, ...rest } = props;
  return (
    <Stack
      direction="row"
      sx={{
        m: 1,
        justifyContent: "center",
        alignContent: "center",
        display: "flex",
      }}
    >
      {/* TODO: FIXME: I NEED TO FIX THE TYPE IN THE PROPS TO MAKE THIS WORK BUT IM STRUGGLING WITH IT RIGHT NOW */}
      {/* <props.icon
        sx={{
          marginRight: 1,
          height: "100%",
          color: "white",
        }}
      /> */}
      <Typography variant="body1">{props.message}</Typography>
    </Stack>
  );
}
