import React, { useState, useEffect } from "react";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { Box, Stack, styled } from "@mui/material";
import { employeesResult } from "../pages/MyCompanyPage";

const columns: GridColDef[] = [
  {
    field: "name",
    headerName: "Name",
    align: "left",
    headerAlign: "left",
    width: 200,
    editable: false,
  },
  {
    field: "role",
    headerName: "Role",
    align: "center",
    headerAlign: "center",
    width: 150,
    editable: false,
  },
  {
    field: "email",
    headerName: "Email",
    align: "right",
    headerAlign: "right",
    width: 200,
    editable: false,
  },
];

export const EmployeesTable: React.FC = () => {
  return (
    <Stack
      direction="column"
      sx={{
        justifyContent: "center",
        alignContent: "center",
        display: "flex",
      }}
    >
      <Box
        sx={
          {
            // background: "white",
            // height: "100%",
          }
        }
      >
        <DataGrid
          sx={{ background: "white" }}
          rows={employeesResult}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 7,
              },
            },
          }}
          // TODO: PAGE SIZE AUTOMATIC
          pageSizeOptions={[7]}
          // checkboxSelection
          disableRowSelectionOnClick
        />
      </Box>
    </Stack>
  );
};
